export const navData = [
    {
        path: 'home',
        Hotels: 'HOME'
    },
    {
        path: 'about',
        Hotels: 'ABOUT'
    },
    {
        path: 'sermons',
        Hotels: 'EVANGELISM'
    },
    {
        path: 'events',
        Hotels: 'EVENTS'
    },
    // {
    //     path: 'causes',
    //     Hotels: 'CAUSES'
    // },
    {
        path: 'blog',
        Hotels: 'EDUCATION'
    },
    {
        path: 'pastor',
        Hotels: 'PASTOR'
    },
    {
        path: 'contact-us',
        Hotels: 'CONTACT'
    }
]
