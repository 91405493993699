import { useState, useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import logo_church from "../../Assets/images/original_logo.png";
import { navData } from "../../Data/navData";
import { Link } from "react-scroll";
export default function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  // const [activeLink, setActiveLink] = useState(navData[0].path);

  // const handleLinkClick = (path) => {
  //   setActiveLink(path);
  // };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScrollChange = () => {
    if (window.scrollY > 200) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScrollChange);
    return () => {
      window.removeEventListener("scroll", setScrolling);
    };
  }, []);

  return (
    <div
      className={` w-full bg-black shadow-md left-0 right-0 top-0 fixed z-30 ${
        scrolling
          ? "bg-white font-medium text-black"
          : " md:shadow-transparent md:bg-transparent text-white"
      }`}
    >
      <div className="w-full flex gap-5 items-center justify-between text-lg px-8 md:px-4 py-2 md:max-w-6xl md:mx-auto ">
        <Link to="/">
          <div className="flex gap-1 pt-2 items-center">
            <img className="h-10 lg:h-[70px]" src={logo_church} alt="Church logo" />
            <h2 className="pt-6 pb-6 text-base md:text-lg italic font-great-vibes font-bold text-black">
              Evangelical <br /> Friends Church of Rwanda
            </h2>
          </div>
        </Link>
        <div className="flex items-center lg:hidden ">
          <button className="text-xl" onClick={toggleMenu} onMouseLeave={toggleMenu}>
            <AiOutlineMenu size={28} />
          </button>
          {menuOpen && (
            <div className="flex flex-col space-y-2 text-lg bg-white text-black p-6 absolute top-24 right-6 z-20">
              {navData.map((item) => {
                return (
                  <Link
                    to={item.path}
                    smooth={true}
                    duration={300}
                    aria-label={`Scroll to ${item.Hotels}`}
                    className="text-sm font-roboto border-b-2 border-transparent cursor-pointer hover:border-yellow-300 transition-transform transform duration-700 hover:ease-in-out"
                  >
                    {item.Hotels}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
        <div className=" hidden lg:flex gap-6 text-lg  text-black">
          {navData.map((item) => {
            return (
              <Link
                to={item.path}
                smooth={true}
                duration={300}
                aria-label={`Scroll to ${item.Hotels}`}
                activeClass="text-yellow-500"
                className={`text-sm font-roboto border-b-2 border-transparent cursor-pointer hover:border-yellow-300 transition-transform transform duration-700 hover:ease-in-out `}
              >
                {item.Hotels}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
