import React from 'react'
import { PastorData } from '../../Data/PastorData'
import { Link } from "react-router-dom";
import Heading from './Heading';
export default function Pastor() {
  return (
   <div className='w-full max-w-6xl mx-auto' id="pastor">
    <Heading title="P A S T O R S" subtitle="Church Pastor" description=""/>

    <div className='grid grid-cols-1 lg:flex gap-10 text-center px-8 pb-8'>
        {
            PastorData.map((items) => {
                return(
                    <div>
                        <div className='flex justify-center'><img className='object-cover w-full h-96 rounded' src={items.servicesIcon}  alt="pastor"/></div>
                        <div className='flex flex-col items-center pt-2' >
                        <div className='text-[25px] font-[roboto]'>{items.Services}</div>
                        <div className='text-[#4c4c4c] font-[roboto]'>{items.ServicesDescription}</div>
                            <div className='flex gap-2 items-center font-[roboto] pt-4'>
                                <div>
                                    <Link to={items.IstaLink}>
                                        {items.Ista}
                                    </Link>
                                </div> 
                                <div>
                                    <Link to={items.TwitLink}>
                                        {items.Twit}
                                    </Link>
                                </div> 
                                <div>
                                    <Link to={items.FaceLink}>
                                        {items.Face}
                                    </Link>
                                </div> 
                            </div>
                        </div>
                    </div>
                )
            })
        }
    </div>
   </div>
  )
}


