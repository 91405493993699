import React, { useState, useEffect, useCallback } from "react";
import bible from "../../Assets/images/bible.jpeg";
import bgHolly from "../../Assets/images/newbg1.jpeg";
import Navbar from "../Sections/Navbar";

const SlidingImages = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images] = useState([bible, bgHolly]);
  const [isFading, setIsFading] = useState(false);

  const nextSlide = useCallback(() => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % images.length);
      setIsFading(false);
    }, 400);
  }, [currentIndex, images.length]);

  useEffect(() => {
    const timer = setInterval(nextSlide, 3000);

    return () => clearInterval(timer);
  }, [nextSlide]);



  return (
    <div className="relative ">
         <img
        src={images[currentIndex]}
        className={`object-cover object-center h-[900px] lg:h-full w-full ${
          isFading ? "opacity-30" : "opacity-100"
        } transition-opacity duration-1000`}
        alt={`Slide ${currentIndex}`}
      />
      <div className="absolute z-20 px-12 top-64 md:bottom-64 w-full flex flex-col items-center justify-center">
        <div className="text-white flex gap-3 font-[roboto] font-bold">
          <div className="h-[1px] w-32 bg-white mt-[10px] hidden md:block"></div>
          <div>Evangelical friends church of Rwanda</div>
          <div className="h-[1px] w-32 bg-white mt-[10px] hidden md:block"></div>
        </div>
        <div className=" text-5xl text-white  uppercase font-[roboto] py-5 tracking-widest text-center">
          We love <span className="text-[#ffd615]">God, </span>we
          <br />
          beleive in god{" "}
        </div>
        <div className="text-white font-[roboto] py-5 text-xl text-center">
        To see the kingdom of God growing among Rwandans, being transformed through the Gospel of Jesus Christ. <br /> Shining his light on all nations and developing holistically.
        </div>
        <div className="flex gap-x-4">
          <button className="bg-[#ffd615] text-white rounded-full px-5 py-2 w-fit">
            Be part of use
          </button>
          <button className="text-[#ffd615]  border border-yellow-500 rounded-full px-5 py-2">
            Read more
          </button>
        </div>
      </div>
      <Navbar/>
    </div>
  );
};

export default SlidingImages;
