import React from 'react'

export default function Partners() {
  return (
    <div className='grid gap-2 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 text-center py-12 box-border md:border border-gray-200'>
        <div className='w-full px-12 py-4 border-b-2 md:border-r-2'>
            <div className='text-[20px] font-bold font-[roboto]'>PARTNERS</div> <br />
            <div className='text-[#4c4c4c] font-[roboto]'>In this section you can highlight partners, add logos and hyperlink their websites links for easy access.</div>
        </div>
        <div className='w-full px-12 py-4 border-b-2 md:border-r-2'>
            <div className='text-[20px] font-bold font-[roboto]'>RESOURCES</div> <br />
            <div className='text-[#4c4c4c] font-[roboto]'>Consult any one at the office to give you the EEAR Legal statute in English, French, and Kinyarwanda, kindly feel free to add more resources such as links and youtube videos.</div>
        </div>
    </div>
  )
}
