import React from 'react'
import SlidingBibleImages from '../Components/Sections/SlidingBibleImages'
import Welcome from '../Components/Sections/Welcome'
import Donation from '../Components/Sections/Donation'
import Services from '../Components/Sections/Services'
import Sermons from '../Components/Sections/Sermons'
import UpcomingEvents from '../Components/Sections/UpcomingEvents'
import Blog from '../Components/Sections/Blog'
import Pastor from '../Components/Sections/Pastor'
import ChurchGallery from '../Components/Sections/ChurchGallery'
import ContactUs from '../Components/Sections/ContactUs'
import Footer from '../Components/Sections/Footer'
import AboutusData from '../Components/Sections/Aboutus'
import Partners from '../Components/Sections/Partners'
import PostalTraining from '../Components/Sections/PostalTraining'


export default function Home() {
  return (
    <div>
        <SlidingBibleImages />
        <Welcome />
        <Donation />
        <Services />
        <Sermons />
        <AboutusData />
        <UpcomingEvents />
        {/* <UpcomingEventsList className="block md:hidden"/> */}
        <Blog />
        <Partners />
        <Pastor />
        <PostalTraining />
        <ContactUs />
        <ChurchGallery />
        <Footer/>
    </div>
  )
}
