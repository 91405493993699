import React from 'react'
import { AboutusData } from '../../Data/AboutusData'

export default function Aboutus() {
  return (
        <div className='grid gap-4 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-2 text-center py-12 box-border w-full max-w-6xl mx-auto '>
            {
                AboutusData.map((item) => {
                    return(
                        <div className='w-full py-4 border-b-2 md:border-r-2 my-14'>
                            <div className='text-[20px] font-bold font-[roboto]'>{item.Title}</div> <br />
                            <div className='text-[#4c4c4c] font-[roboto] px-10'>{item.Description}</div>
                        </div>
                    )
                })
            }
        </div>
  )
}
