import React from 'react'
import { ServicesData } from '../../Data/ServicesData'
import Heading from './Heading'

export default function Services() {
  return (
   <div className='w-full max-w-6xl mx-auto pb-28'>
    <Heading title="S E R V I C E S"  subtitle="Evangelical friends church of Rwanda"  description="COME WORSHIP WITH US ON WEEKDAYS AND SUNDAYS ANYWHERE IN RWANDA"/>
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-center '>
        {
            ServicesData.map((items) => {
                return(
                    <div className='px-3'>
                        <div className='flex justify-center text-6xl text-[#fbcf00]'>{items.servicesIcon}</div>
                        <div className='text-[25px] text-center font-[roboto] py-2'>{items.Services}</div>
                        <div className='text-gray-500 text-center font-[roboto]'>{items.ServicesDescription}</div>
                    </div>
                )
            })
        }
    </div>
   </div>
  )
}
