import React from 'react'

const Heading= ({title , subtitle , description, className }) => {
  return (
    <div className={`py-20 px-4 flex flex-col items-center gap-y-4${className}`}> 
      <div className="flex items-center gap-3">
        <div className="bg-[#b2b2b2] h-[1px] w-32 mt-3 hidden md:block"></div>
        <div className="text-[#b2b2b2]">{title}</div>
        <div className="bg-[#b2b2b2] h-[1px] w-32 mt-3 hidden md:block"></div>
      </div>
    
    <div className="flex items-center justify-center text-lg md:text-5xl font-bold font-[roboto] py-2">
      {subtitle}
    </div>
    <p className="text-center text-[#9f9f9f] font-[roboto]">
      {description}
    </p>
    </div>
  )
}

export default Heading
