import { BiLogoFacebook } from 'react-icons/bi';
import { AiOutlineInstagram } from 'react-icons/ai';
import { AiOutlineTwitter } from 'react-icons/ai';
export const SocialData = [
    {
        // path: '/home',
        SocialIcon: <BiLogoFacebook />,
        Social: '/'
    },
    {
        // path: '/Social',
        SocialIcon: <AiOutlineInstagram />,
        Social: 'https://www.instagram.com/eglise_des_amis_rwanda86/'
    },
    {
        // path: '/sermons',
        SocialIcon: <AiOutlineTwitter />,
        Social: '/'
    }

]