import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { BiCurrentLocation } from 'react-icons/bi';
import { IoMdCall } from 'react-icons/io';
import { SiMinutemailer } from 'react-icons/si';
import { GiEarthAmerica } from 'react-icons/gi';
import { AiOutlineSend } from 'react-icons/ai';
import Heading from './Heading';

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [sendingStatus, setSendingStatus] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic form validation
    const errors = {};
    if (!formData.name) {
      errors.name = 'Please enter your name';
    }
    if (!formData.email) {
      errors.email = 'Please enter your email';
    }
    if (!formData.subject) {
      errors.subject = 'Please enter a subject';
    }
    if (!formData.message) {
      errors.message = 'Please enter a message';
    }
    setValidationErrors(errors);
    
    // If there are validation errors, don't send the form
    if (Object.keys(errors).length > 0) {
      return;
    }

    setSendingStatus('Sending...');

    emailjs.sendForm('service_7tb8xj9', 'template_1x9wsed', e.target, 'r5TaQoF09s9oDGmz_')
      .then((result) => {
        console.log(result.text);
        setSendingStatus('Message sent successfully!');
      }, (error) => {
        console.log(error.text);
        setSendingStatus('Failed to send message. Please try again.');
      });

    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  };

  return (
    <div className='mb-12 w-full max-w-6xl mx-auto' id="contact-us">
      <Heading title="C O N T A C T" subtitle="Contact Us" description="" />
      <div className=' flex flex-col-reverse lg:flex-row gap-x-10 justify-center'>
        <div className='md:space-y-6'>
          <div className='bg-gray-50 flex gap-x-2 p-4'>
            <div className='mt-1 text-[#ffd615]'><BiCurrentLocation /></div>
            <div className='flex  flex-col gap-4font-[roboto]'>
              <div className='text-lg font-medium'>Address</div>
              <div className=' text-[#9f9cb5]'>KK15 Rd, 71 St  Kigali,Rwanda.</div>
            </div>
          </div>
          <div className='bg-gray-50 flex gap-x-2 p-4'>
            <div className='mt-1 text-[#ffd615]'><IoMdCall /></div>
            <div className='flex  flex-col gap-4font-[roboto]'>
              <div className='text-lg font-medium'>Contact Number</div>
              <div className='text-[#ffd615]'>+250 788849548,+250 788844385, +250 789687372</div>
            </div>
          </div>

          <div className='bg-gray-50 flex gap-x-2 p-4'>
            <div className='mt-1 text-[#ffd615]'><SiMinutemailer /></div>
            <div className='flex  flex-col gap-4font-[roboto]'>
              <div className='text-lg font-medium'>Email Address</div>
              <div className='text-[#ffd615]'>
                <a href='mailto:eearwandaym@gmail.com'>eearwandaym@gmail.com</a>
              </div>
            </div>
          </div>

          <div className='bg-gray-50 flex gap-x-2 p-4'>
            <div className='mt-1 text-[#ffd615]'><GiEarthAmerica /></div>
            <div className='flex  flex-col gap-4font-[roboto]'>
              <div className='text-lg font-medium'>Website</div>
              <div className='text-[#ffd615]'>www.evangelicalfriendschurchofrwanda.com</div>
            </div>
          </div>
        </div>
        <div className='px-12 py-8 bg-gray-50 w-full lg:w-1/2'>
          <form onSubmit={handleSubmit}>
            <div>
              <input
                type="text"
                className='h-[50px] border-2 mb-6 rounded px-4 w-full'
                placeholder='Your Name'
                name="name"
                id=""
                value={formData.name}
                onChange={handleChange}
              />
              {validationErrors.name && <div className="text-red-500">{validationErrors.name}</div>}
            </div>
            <div>
              <input
                type="email"
                className='h-[50px] border-2 mb-6 rounded px-4 w-full'
                placeholder='Your Email'
                name="email"
                id=""
                value={formData.email}
                onChange={handleChange}
              />
              {validationErrors.email && <div className="text-red-500">{validationErrors.email}</div>}
            </div>
            <div>
              <input
                type="text"
                className='h-[50px] border-2 mb-6 rounded px-4 w-full'
                placeholder='Subject'
                name="subject"
                id=""
                value={formData.subject}
                onChange={handleChange}
              />
              {validationErrors.subject && <div className="text-red-500">{validationErrors.subject}</div>}
            </div>
            <div>
              <textarea
                name="message"
                className='h-[170px] border-2 rounded px-4 w-full'
                placeholder='Message'
                id=""
                cols="30"
                rows="10"
                value={formData.message}
                onChange={handleChange}
              >
              </textarea>
              {validationErrors.message && <div className="text-red-500">{validationErrors.message}</div>}
            </div>
            <div className='text-left pt-6 '>
              <button className='bg-[#ffd615] text-white rounded-full px-5 py-2'>
                <div className='flex gap-2 font-[roboto]'>
                  <div>Send Message</div>
                  <div className='mt-1'>
                    <AiOutlineSend />
                  </div>
                </div>
              </button>
            </div>
            {sendingStatus && <div>{sendingStatus}</div>}
          </form>
        </div>
      </div>
    </div>
  )
}
