import React from 'react'
export default function PostalTraining() {
  return (
  <div className='grid gap-2 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 text-center py-12 box-border md:border border-gray-200'>
  <div className='w-full px-3 py-4 border-b-2 md:border-r-2'>
      <div className='text-[20px] font-bold font-[roboto]'>PASTORAL TRAINING</div> <br />
      <div className='text-[#4c4c4c] font-[roboto]  px-12'>Rwanda Friends Theological College is a Diploma–Level Bible College in Rwanda where a student is trained, equipped and prepared in two years of studies to become active in God’s Ministry. RFTC is a place where you can come and acquire these and other capacities:</div>
      <div className='text-center pt-8 text-[#4c4c4c] font-[roboto] px-12'>
      <ul>
                    <li>1. The power to live, implying the development of Character attained through personal response to Jesus Christ in faith and obedience</li>
                    <li>2. The power to do, implying the development of the necessary abilities for doing specific tasks</li>
                    <li>3. The power to think, implying problems solving for self, for the community, and for society.</li>
                </ul>
      </div>
  </div>
  <div className='w-full px-12 py-4 border-b-2 md:border-r-2'>
      <div className='text-[20px] font-bold font-[roboto]'>PEACE AND DEVELOPMENT</div> <br />
      <div className='text-[#4c4c4c] font-[roboto]'>The Evangelical Friends Church of Rwanda/Eglise evangelique des Amis au Rwanda partners with many national and international peacebuilding and development organizations that are actively working to create sustainable peace among Rwandans and to help these people to also develop.</div>
  </div>
</div>
  )
}
