import { MdOutlineDateRange } from 'react-icons/md';
import bible from '../Assets/images/bible.jpeg'
export const UpcomingEventsData = [
    {
        photo: bible,
        personIcon: <MdOutlineDateRange />,
        StartingTime: '8:30am',
        EndingTime: '11:30am',
        eventTitle: 'Sharing Our Faith & Gospel',
        pasterName: '08 October 2023',
        eventDescription: 'Kagarama',
    },
    {
        photo: bible,
        personIcon: <MdOutlineDateRange />,
        StartingTime: '8:30am',
        EndingTime: '11:30am',
        eventTitle: 'Sharing Our Faith & Gospel',
        pasterName: '08 October 2023',
        eventDescription: 'Kagarama',
    },
    {
        photo: bible,
        personIcon: <MdOutlineDateRange />,
        StartingTime: '5:00 pm',
        EndingTime: '8:00 pm',
        eventTitle: 'New event',
        pasterName: '',
        eventDescription: 'Kagarama',
    }
    

]