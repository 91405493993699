import { AiOutlineInstagram } from 'react-icons/ai';
import { AiOutlineTwitter } from 'react-icons/ai';
import { BiLogoFacebook } from 'react-icons/bi';
import pastor from '../Assets/images/mupenda.jpeg';
import pastor1 from '../Assets/images/cyungurad.jpeg';
import pastor2 from '../Assets/images/jay p.jpeg';
export const PastorData = [
    {
        ServicesDescription: 'Legal Representative and general superintendent',
        servicesIcon: pastor,
        Ista:<AiOutlineInstagram />,
        Twit: <AiOutlineTwitter />,
        Face: <BiLogoFacebook />,
        Services: 'Rev. Aaron MUPENDA',
        IstaLink:'https://www.instagram.com/mupendaaaron/',
        TwitLink: 'https://x.com/mupendaaaron?s=11&t=_BGrIVX20rl7rzPiQoKTzQ',
        FaceLink: 'https://www.facebook.com/aaron.mupenda?mibextid=LQQJ4d',
    },
    {
        ServicesDescription: 'Assistant Legal Representative',
        servicesIcon: pastor1,
        Ista:<AiOutlineInstagram />,
        Twit: <AiOutlineTwitter />,
        Face: <BiLogoFacebook />,
        Services: 'Rev. Dieudone CYUNGURA',
        IstaLink:'',
        TwitLink: '',
        FaceLink: '',
    },
    {
        ServicesDescription: 'Assistant General superintendent',
        servicesIcon: pastor2,
        Ista:<AiOutlineInstagram />,
        Twit: <AiOutlineTwitter />,
        Face: <BiLogoFacebook />,
        Services: 'Rev. Jean paul NSEKANABO',
        IstaLink:'',
        TwitLink: '',
        FaceLink: '',
    }
]
