import React from 'react'
import { ChurchGalleryData } from '../../Data/ChurchGalleryData'
import Heading from './Heading'
export default function ChurchGallery() {
  return (
   <div className='w-full max-w-6xl mx-auto mb-32' id="gallery">
    <Heading title="G A L L E R Y" subtitle="Church Photo Gallery" description=""/>
        <div className='grid grid-col-1  lg:grid-cols-4 gap-10 text-center mx-[30px] '>
        {
            ChurchGalleryData.map((items) => {
                return (
                    <div className='flex-1'>
                        <div className='flex justify-center text-[84px] text-[#fbcf00] h-[400px]'><img className='object-cover w-full' src={items.servicesIcon} alt={items.Services} /></div>
                        {/* <div className='text-[25px] font-[roboto] py-2'>{items.Services}</div>
                        <div className='text-[#4c4c4c] font-[roboto]'>{items.ServicesDescription}</div> */}
                    </div>
                )
            })
        }
        </div>


   </div>
  )
}


