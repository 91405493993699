import bible from '../Assets/images/church1.jpg'
import bibles from '../Assets/images/holly_bible.webp'
import bibleNew from '../Assets/images/holly_bible1.jpeg'

export const SermonsData = [
    {
        Pastor: 'We are in the process of constructing a new church that will stand as a beacon of faith and community for generations to come.',
        SermonsDescription: 'Kagarama',
        SermonsIcon: bible,
        Sermons: 'Evangelical friends church of Rwanda head Quarter (Yearly meeting)'
    },
    {
        Pastor: 'We are in the process of constructing a new church that will stand as a beacon of faith and community for generations to come.',
        SermonsDescription: 'Kanombe',
        SermonsIcon: bibles ,
        Sermons: 'Quarterly meeting of kigali'
    },
    {
        Pastor: 'We are in the process of constructing a new church that will stand as a beacon of faith and community for generations to come.',
        SermonsDescription: 'Musanze',
        SermonsIcon: bibleNew,
        Sermons: 'North region Head Quarter'
    },
    {
        Pastor: 'We are in the process of constructing a new church that will stand as a beacon of faith and community for generations to come.',
        SermonsDescription: <p>Gisenyi/Rubavu <br />Kamembe/Rusizi</p>,
        SermonsIcon: bible,
        Sermons: 'Western region​ Head Quarter'
    },
    {
        Pastor: 'We are in the process of constructing a new church that will stand as a beacon of faith and community for generations to come.',
        SermonsDescription: 'Kayonza',
        SermonsIcon: bibles ,
        Sermons: 'Eastern Region Head Quarter'
    }
]
