import React from 'react'
import Upcoming from '../../Assets/images/gospel-banner.webp'
import Heading from './Heading'
import UpcomingEventsList from './UpcomingEventsList'

export default function UpcomingEvents() {
  return (
    <div className='relative sm:h-[550px]'>
        <div className='relative z-10'>
            <img className='w-full h-[400px] object-cover' src={Upcoming} alt="Upcoming Events" srcset="" />
            <div className='absolute bottom-12 w-full flex flex-col items-center justify-center'>
            <Heading title=" E V E N T S" subtitle="Upcoming Events"/>
            </div>
        </div>
        <UpcomingEventsList/>
    </div>
  )
}
