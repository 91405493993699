import { GiPrayer } from 'react-icons/gi';
import { BiBible } from 'react-icons/bi';
import { GiDoorRingHandle } from 'react-icons/gi';
import { BsPersonHearts } from 'react-icons/bs';
export const ServicesData = [
    {
        ServicesDescription: <p><b>Weekdays</b> <br />
        Tuesday from 5:00 am to 7:00 pm <br />   
        Sundays From 8:00 am to 12:00 o'clock 
        </p>,
        servicesIcon: <GiPrayer />,
        Services: 'Evangelical friends church of Rwanda Headquarters Kagarama'
    },
    {
        ServicesDescription:  <p><b>Weekdays</b> <br />
        Sunday from 08 pm to 10 pm<br />   
        Sundays From 11:30 am to 01:30 pm
        </p>,
        servicesIcon: <BiBible />,
        Services: 'Evangelical friends church of Rwanda Kanombe(Quarterly meeting, Kigali City)'
    },
    {
        ServicesDescription: <p><b>Weekdays</b> <br />
        Tuesday From 05:00 pm to 06:00 pm<br />   
        Sundays From 09:00 am to 12:00 o'clock
        </p>,
        servicesIcon: <GiDoorRingHandle />,
        Services: 'Evangelical friends church of Rwanda Gisenyi(Quarterly meeting, West region)'
    },
    {
        ServicesDescription: <p><b>Weekdays</b> <br />
        Tuesday and Friday from 4:00 pm to 5:30 pm<br />   
        Sundays From 09:00 am to 12:00 O'clock
        </p>,
        servicesIcon: <BsPersonHearts />,
        Services: 'Evangelical friends church of Rwanda Musanze(Quarterly meeting, North region)'
    },
    {
        ServicesDescription: <p>  
        Sundays From 09:00 am to 12:00 O'clock
        </p>,
        servicesIcon: <BsPersonHearts />,
        Services: 'Evangelical friends church of Rwanda katarara(eastern Region)​'
    }
]
