import { AiOutlineInstagram } from 'react-icons/ai';
import { AiOutlineTwitter } from 'react-icons/ai';
import { BiLogoFacebook } from 'react-icons/bi';
import church from '../Assets/images/new_church.jpeg';
import church1 from '../Assets/images/new_church1.jpeg';
import church2 from '../Assets/images/new_church2.jpeg';
import pastor1 from '../Assets/images/welcome_to_church.webp';
export const ChurchGalleryData = [
    {
        ServicesDescription: 'Legal Representative and general superintendent',
        servicesIcon: church,
        Ista:<AiOutlineInstagram />,
        Twit: <AiOutlineTwitter />,
        Face: <BiLogoFacebook />,
        Services: 'Rev. Aaron MUPENDA'
    },
    {
        ServicesDescription: 'Assistant Legal Representative',
        servicesIcon: church1,
        Ista:<AiOutlineInstagram />,
        Twit: <AiOutlineTwitter />,
        Face: <BiLogoFacebook />,
        Services: 'Rev. Dieudone CYUNGURA'
    },
    {
        ServicesDescription: 'Assistant General superintendent',
        servicesIcon: church2,
        Ista:<AiOutlineInstagram />,
        Twit: <AiOutlineTwitter />,
        Face: <BiLogoFacebook />,
        Services: 'Rev. Jean paul NSEKANABO'
    },
    {
        ServicesDescription: 'Legal Representative and general superintendent',
        servicesIcon: pastor1,
        Ista:<AiOutlineInstagram />,
        Twit: <AiOutlineTwitter />,
        Face: <BiLogoFacebook />,
        Services: 'Rev. Aaron MUPENDA'
    }
]
