import React from "react";
import { BlogData } from "../../Data/BlogData";
import TodayVerse from "./TodayVerse";
import { AiOutlineArrowRight } from "react-icons/ai";
import Heading from "./Heading";

export default function Blog() {
  return (
    <div id="blog">
      <TodayVerse />
      <div className="bg-gray-50 w-full">
        <div className=" max-w-6xl mx-auto">
            <Heading title="E D U C A T I O N"  subtitle="Education"  description="As the evangelical Friends church of Rwanda/Eglise Evangelique Des Amis au Rwanda we are dedicated to serving God as well as the people of God, in this spirit we commit ourselves to generate hope through educating Rwandans in general.
We have many schools with excellent standards (nursery, primary, secondary ) Schools and one pastoral Training College."/>
 
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 px-6">
            {BlogData.map((items) => {
              return (
                <div>
                  <div className="flex relative text-[#fbcf00]">
                    <img
                      className="object-cover w-full h-96"
                      src={items.servicesIcon}
                      alt="service icon"
                    />
                    {/* <div className="absolute top-2 left-2 flex text-white">
                      <div className="text-[60px]">04</div>
                      <div className="mt-5">
                        <div>2014</div>
                        <div>June</div>
                      </div>
                    </div> */}
                  </div>
                  <div className="text-[25px] font-[roboto] py-2 text-center">
                    {items.Services}
                  </div>
                  <div className="text-[#4c4c4c] font-[roboto] text-center">
                    {items.ServicesDescription}
                  </div>
                  <div className="px-4 text-center my-10 ">
                    <button className="bg-[#ffd615] text-white rounded-full px-5 py-2">
                      <div className="flex gap-2 font-[roboto]">
                        <div>Read more</div>
                        <div className="mt-1">
                          <AiOutlineArrowRight />{" "}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
