export const ConnectData = [
    {
        // path: '/home',
        Connect: 'Home Groups'
    },
    {
        // path: '/Connect',
        Connect: 'Recovery Groups'
    },
    {
        // path: '/sermons',
        Connect: 'Memberships'
    },
    {
        // path: '/events',
        Connect: 'Children & Students'
    },
    {
        // path: '/causes',
        Connect: 'Volunteer'
    },
    {
        // path: '/blog',
        Connect: 'Counseling'
    },
    {
        // path: '/blog',
        Connect: 'Assistance'
    }
]

