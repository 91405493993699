import React from "react";
import welcome_church from "../../Assets/images/welcome_to_church.webp";
import { AiOutlineCheck } from "react-icons/ai";
export default function Welcome() {
  return (
      <div className="grid font-sans grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-6 px-6 lg:px-0 w-full" id="about">
        <div className="w-full">
          <img src={welcome_church} alt="Welcome Church" className="w-full object-cover h-[400px] md:h-[560px]" />
        </div>
        <div className=" w-full md:w-1/2 md:py-16">
          <div className="text-[32px] font-[roboto] font-bold mb-6">
            Welcome <span className="text-[#ffd615]">Evangelical</span> Friends Church of Rwanda
          </div>
          <div>OUR LEADERSHIP</div>
          <div className="text-[#d3d3d3] my-5">
            The commitment of the staff and leadership at{" "}
            <span className="text-[#ababab]">Evangelical friends church of Rwanda</span>{" "}
            of Rwanda is at the heart of what makes us a living, thriving
            church. They are dedicated to serving the{" "}
            <span className="text-[#ababab]">community and strengthening</span>{" "}
            the impact of our church on people’s lives.
          </div>
          <div className="text-[#ffd615] mb-5"></div>
          <div className="text-[#809f9f] lowercase">
            <div className="flex gap-2">
              <div className="text-[8px] mt-2 bg-[#ffd615] text-white px-[2px] pt-[2px] h-[12px] rounded-full">
                <AiOutlineCheck />
              </div>
              <div></div>
            </div>
            <div className="flex gap-2">
              <div className="text-[8px] mt-2 bg-[#ffd615] text-white px-[2px] pt-[2px] h-[12px] rounded-full">
                <AiOutlineCheck />
              </div>
              <div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
