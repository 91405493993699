export const aboutData = [
    {
        // path: '/home',
        About: 'Staff'
    },
    {
        // path: '/about',
        About: 'Beliefs'
    },
    {
        // path: '/sermons',
        About: 'History'
    },
    {
        // path: '/events',
        About: 'Mission'
    },
    {
        // path: '/causes',
        About: 'Wedding & Funerals'
    },
    {
        // path: '/blog',
        About: 'Fellowships'
    }
]