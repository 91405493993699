import React from 'react'
import { UpcomingEventsData } from '../../Data/UpcomingEventsData'

export default function UpcomingEventsList({className}) {
  return (

        <div className='grid grid-cols-1 lg:grid-cols-3 gap-14 px-8 md:absolute top-72 pb-20 mb-20 z-20'id="events">
            {
                UpcomingEventsData.map((items) => {
                    return(
                        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2  items-end sm:mb-[240px] md:mb-[0px] xl:mb-6'>
                            <div>
                                <img className='h-[270px] object-cover w-[420px]' src={items.photo} alt="Upcoming Events" srcset="" />
                            </div>
                            <div className='pt-10 border h-[300px] bg-white'>
                                <div className='bg-[#ffd615] w-[200px]  text-white mb-4 px-4 py-1'>{items.StartingTime} - {items.EndingTime}</div>
                                <div className='px-4 mb-4'>{items.eventTitle}</div>
                                <div className='flex gap-2 mb-4 px-4'>
                                    <div className='mt-1 text-[#a69ba3]'>{items.personIcon}</div>
                                    {/* <div className='text-[#a69ba3]'>by pastor: </div> */}
                                    <div className='text-[#a69ba3]'>{items.pasterName}</div>
                                </div>
                                <div className='text-[#a69ba3] px-4 mb-4'>{items.eventDescription}</div>
                                <div className='px-4 '>
                                    <button className='bg-[#ffd615] text-white rounded-full px-5 py-2'>Join Us</button>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
  )
}
