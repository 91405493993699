import React from "react";
import { aboutData } from "../../Data/aboutData";
import { Link } from "react-router-dom";
import { ConnectData } from "../../Data/ConnectData";
import { SocialData } from "../../Data/SocialData";

export default function Footer() {
  return (
    <div className="bg-[#1b1919] w-full" id="footer">
      <div className=" text-[#809f9f] font-[roboto] py-24 px-6 max-w-6xl mx-auto">
        <div className="flex flex-col md:flex-row gap-20">
          <div>
            <div className="text-white font-bold text-[21px] pb-[20px] font-[Pacifico]">
            Evangelical friends church of Rwanda
            </div>
            <div>
              To see the kingdom of God growing among Rwandans, being
              transformed through the Gospel of Jesus Christ. Shining his light
              on all nations and developing holistically.
            </div>
            <div className="flex gap-2 mt-10">
              {SocialData.map((item) => {
                return (
                  <div className="bg-[#323030] p-3 rounded-full text-white">
                    <Link to={item.Social}>
                      <div className="text-[38px]">{item.SocialIcon}</div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-[280px]">
            <div className="text-white font-bold text-[21px] pb-[20px]">
              About
            </div>
            <div>
              {aboutData.map((item) => {
                return (
                  <Link to="/">
                    <div className="hover:text-[#ffd615] pb-3">
                      {item.About}
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="w-[280px]">
            <div className="text-white font-bold text-[21px] pb-[20px]">
              Connect
            </div>
            <div>
              {ConnectData.map((item) => {
                return (
                  <div className="hover:text-[#ffd615] pb-3">
                    {item.Connect}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-[350px]">
            <div className="text-white font-bold text-[21px] pb-[20px] ">
              Service Hours
            </div>
            <div className="text-[#605d53] italic">
              Saturday Prayer Meeting:
            </div>
            <div className="text-[#ffd615] italic">10:00 am to 11:30 am</div>
            <div className="text-[#605d53] italic mt-4">Sunday Service:</div>
            <div className="text-[#ffd615] italic">8:30 am to 11:30 am</div>
          </div>
        </div>
        <div class="flex justify-center mt-10">
          Copyright &copy; 2023 Evangelical Friends Church. All rights reserved
        </div>
      </div>
    </div>
  );
}
