export const AboutusData = [
    {
        Title: 'VISION',
        Description: <p>To see the kingdom of God growing among Rwandans, being transformed through the Gospel <br /> of Jesus Christ. Shining his light on all nations and developing holistically.</p>
    },
    {
        Title: 'MISSION',
        Description: 'Shining the Light of Christ across Rwanda and throughout the world…'
    },
    {
        Title: 'GOALS',
        Description: <p>Proclaiming the Good News of Jesus Christ<br /> Promoting education in Rwanda<br /> Advancing community socio-economic development in our country<br /> Building durable peace</p>
    },
    {
        Title: 'VALUES',
        Description: <div className="text-left grid grid-cols-2">
                        <div>
                            <ul className="list-disc">
                                <li>Fellowship</li>
                                <li>Transparency</li>
                                <li>Pacifism</li>
                                <li>Purity</li>
                            </ul>
                        </div>
                        <div>
                            <ul className="list-disc">
                                <li>Stewardship</li>
                                <li>Humility</li>
                                <li>Integrity</li>
                                <li>Equality</li>
                            </ul>
                        </div>
                    </div>
    }
]
