import React from 'react'
import SlidingImages from '../Slide/SlidingImages';
import { Link } from 'react-router-dom'

export default function SlidingBibleImages() {
// const location = useLocation();
// const active = location.pathname;
  return (
    <div className='relative sm:mx-auto sm:w-full' id="home">
    <SlidingImages />
    <div className='absolute hidden top-[290px] left-1/2 transform -translate-x-1/2 w-full sm:w-[750px]'>
        <div className='text-white flex flex-col sm:flex-row items-center gap-3 px-5 sm:gap-5 sm:px-10'>
            <div className='bg-white h-[1px] w-[100px] sm:w-[200px]'></div>
            <div className='font-[roboto] text-base sm:text-20px'>Evangelical friends church of Rwanda</div>
            <div className='bg-white h-[1px] w-[100px] sm:w-[200px]'></div>
        </div>
        <div className='uppercase text-white text-[24px] sm:text-[35px] font-bold font-[roboto] my-3 sm:my-6'>
            <span>Shining the Light of <span className='text-[#ffd615]'>Christ across Rwanda</span> and throughout the world</span>
        </div>
        <div className='font-[roboto] text-white text-sm sm:text-base'>To see the kingdom of God growing among Rwandans, being transformed through the Gospel of Jesus Christ. Shining his light on all nations and developing holistically.</div>
        <div className='flex flex-col sm:flex-row gap-2 px-2 sm:gap-3 sm:px-[200px] mt-4 sm:mt-12'>
            <div className='mt-2 sm:mt-0'>
                <Link to="/">
                    <button type="button" className='bg-[#ffd615] rounded-full px-4 sm:px-6 font-[roboto] text-white py-2'>Be part of us</button>
                </Link>
            </div>
            <div className='mt-2 sm:mt-0'>
                <Link to="/">
                    <button type="button" className='border border-[#ffd615] text-[#ffd615] rounded-full px-4 sm:px-6 font-[roboto hover:text-white hover:bg-[#ffd615] py-2'>Read More</button>
                </Link>
            </div>
        </div>
    </div>
</div>

  )
}
