import React from 'react'
import { DonationData } from '../../Data/DonationData'

export default function Donation() {
  return (
        <div className='grid gap-4 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4 text-center py-12 box-border md:border border-gray-200'>
            {
                DonationData.map((item) => {
                    return(
                        <div className='w-full py-4 border-b-2 md:border-r-2'>
                            <div className='flex items-center justify-center text-[#fbcf00] text-[52px]'>{item.donationIcon}</div>
                            <div className='text-[40px] font-bold font-[roboto]'>{item.Number}</div>
                            <div className='text-[#4c4c4c] font-[roboto]'>{item.Donation}</div>
                        </div>
                    )
                })
            }
        </div>
  )
}
