import React from "react";
import { SermonsData } from "../../Data/SermonsData";
import Heading from "./Heading";
import { BsFillPlayFill } from "react-icons/bs";
import { BiSolidDownload } from "react-icons/bi";
import { Link } from "react-router-dom";

export default function Sermons() {
  return (
    <div className="bg-gray-50 w-full pb-28" id="sermons">
      <div className=" max-w-6xl mx-auto px-6">
      <Heading title="E V A N G E L I S M"  subtitle="EVANGELISM"  description="Evangelical Friends church Rwanda has 76 local Churches and groups, with more than 6,000 members who are shining the Light of Christ to 12 million Rwandans.
​
We operate in all provinces of Rwanda and Kigali City.
"/>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-center">
        {SermonsData.map((items) => {
          return (
            <div>
              <div className="relative">
                <img
                  className="h-[500px] w-full object-cover "
                  src={items.SermonsIcon}
                  alt="Sermons"
                />
                <div className="absolute  bottom-4 left-4 border border-gray-400 opacity-90 right-4 top-4 py-[150px]">
                  <div className="text-white">
                    <div>
                      <span className="font-[roboto] font-bold">Our Activity</span>
                    </div>
                    <div className="py-3 px-2 hover:text-blue-400">{items.Pastor}</div>
                    <div className="flex gap-1 justify-center">
                      <Link to="/">
                        <div className="flex gap-2 justify-center border rounded px-4 py-1 font-[roboto] border-gray-300  hover:border-0 hover:bg-[#fbcf00]">
                          <div className="mt-1">
                            <BsFillPlayFill />
                          </div>
                          <div>WATCH</div>
                        </div>
                      </Link>
                      <Link to="/">
                        <div className="flex gap-2 justify-center border rounded px-4 py-1 font-[roboto] border-gray-300 hover:border-0 hover:bg-[#fbcf00]">
                          <div className="mt-1">
                            <BiSolidDownload />
                          </div>
                          <div>DOWNLOAD</div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="text-[25px] font-[roboto] py-2">
                {items.Sermons}
              </div>
              <div className="text-[#4c4c4c] font-[roboto]">
                {items.SermonsDescription}
              </div> */}
            </div>
          );
        })}
      </div>
    </div>
    </div>
  );
}
