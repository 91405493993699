import primary from '../Assets/images/primary.jpeg'
import bible2 from '../Assets/images/holly_bible.webp'
import postal from '../Assets/images/newbg.jpeg'
import secondary from '../Assets/images/secondary.jpeg'
export const BlogData = [
    {
        ServicesDescription: 'Rwanda Friends Theological College Musanze Disrtict.',
        servicesIcon: postal,
        Services: 'Pastoral Training college'
    },
    {
        ServicesDescription: <ul>
            <li>1. College George fox Kagarama(CGFK) Kigali City</li>
            <li>2. College George Fox Butaro(CGFB) Burera District</li>
            <li>3. Friends school of kamembe(FSK) Rusizi District</li>
            <li>4. Ecole Secondaire de Kidaho(ESK) Burera District</li>
            <li>5. G.S Katarara Bugesera District</li>
            <li>6. G.S Rugarama Burera District</li>
        </ul>,
        servicesIcon: secondary,
        Services: 'Secondary schools'
    },
    {
        ServicesDescription: <ul>
        <li>1. Ecole Primaire Kagarama(EPK) Kicukiro District/Kigali</li>
        <li>2. 2&5 Christian Academy Kicukiro district(Kanombe)</li>
        <li>3. Nonko Primary school Kicukiro district(Kanombe)</li>
        </ul>,
        servicesIcon: primary,
        Services: 'Primary schools'
    },
    {
        ServicesDescription: "We have fourteen(14) nursery schools across Rwanda",
        servicesIcon: bible2,
        Services: 'Nursery Schools'
    }
]
