import React from 'react'
import Upcoming from '../../Assets/images/holly_bible.webp'
import { PiChurchLight } from 'react-icons/pi';

export default function TodayVerse() {
  return (
    <div className='sm:mt-[2890px] md:mt-[1450px] xl:mt-20'>
        <div className='relative'>
            <img className='w-full h-[400px] object-cover' src={Upcoming} alt="Upcoming Events" srcset="" />
            <div className='absolute top-12 md:bottom-32 w-full flex flex-col items-center justify-center'>
                <div className='flex flex-col md:flex-row gap-10 px-6'>
                  <div className='text-6xl md:text-[140px] text-[#fbcf00]' ><PiChurchLight /></div>
                  <div>
                    <div className='text-white flex gap-3 font-[roboto] font-bold'>
                      <div className='h-[1px] w-32 bg-white mt-[10px] hidden md:block'></div>
                      <div>Jesus Christ Said:</div>
                      <div className='h-[1px] w-32 bg-white mt-[10px] hidden md:block'></div>
                    </div>
                    <div className='text-[#ffd615] font-[roboto] py-5'>"16 For God so loved the world, that he gave his only begotten Son, <br /> that whosoever believeth in him should not perish, but have everlasting life."</div>
                    <div className='text-white font-bold font-[roboto]'>John 3:16 KJV</div>
                  </div>
                </div>
            </div>

        </div>
    </div>
  )
}

