import { FaUsers } from 'react-icons/fa';
import { FaUserAlt } from 'react-icons/fa';
import { AiTwotoneHome } from 'react-icons/ai';
export const DonationData = [
    {
        Number: '7000',
        donationIcon: <FaUsers />,
        Donation: 'MEMBERS'
    },
    {
        Number: '51',
        donationIcon: <FaUserAlt />,
        Donation: 'PASTORS'
    },
    {
        Number: '10 +',
        donationIcon: <FaUserAlt />,
        Donation: 'PARTNERS'
    },
    {
        Number: '76',
        donationIcon: <AiTwotoneHome />,
        Donation: 'CHURCHES'
    }
]
